import React from "react";

const Navbar = () => {

 
  return (
    <nav>
      <div className="nav__logo">Photo Gallery App</div>
     
    </nav>
  );
};

export default Navbar;
